import { Avatar, CardHeader, Chip, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import thinqIcon from "../assets/images/ic_launcher_thinq.png";
import { debug } from "../data/constants";
import { db } from "../firebase/firebase";
// import CameraIndoorIcon from '@mui/icons-material/CameraIndoor';

function TextMessage(props) {
  const { stdemo, image, slider, youtube, kakaotalk, standup, popup, fullscreen, complete } = props;
  const [event, setEvent] = useState("null");
  const [icon, setIcon] = useState(thinqIcon);
  const [from, setFrom] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (standup) {
      setMessage("1시간 동안 앉아서 TV만 보셨어요.\n 일어서서 일분 동안 몸을 움직이세요.");
    } else if (popup) {
      setMessage("일어선채로 1분 동안 움직이세요.");
    } else if (fullscreen) {
      setMessage("아직 1분이 되지 않았는데 앉으시다뇨? \n 기운내서 다시 일어나 보세요!");
    } else if (complete) {
      setMessage("1분 움직이기 챌린지 완료");
    }
  }, [standup, popup, fullscreen, complete]);

  const keyListener = useCallback(
    (e) => {
      setEvent(e.key);
      if (e.key === "Enter") {
        if (image || slider) {
          localStorage.setItem("imgIndex", 0);
          navigate("/detail", {
            replace: true,
            state: { keyevent: e.key, from: from, message: message, slider },
          });
        } else if (youtube) {
          setDoc(doc(db, "thinq_talk", "youtube"), {
            play: true,
          });
          setTimeout(() => {
            navigate("/", { replace: true });
            setDoc(doc(db, "thinq_talk", "application"), {
              poweron: false,
            });
          }, 500);
        } else if (kakaotalk) {
          navigate("/", { replace: true });
          setDoc(doc(db, "thinq_talk", "application"), {
            poweron: false,
          });
        } else if (stdemo) {
          navigate("/", { replace: true });
          setDoc(doc(db, "thinq_talk", "application"), {
            poweron: false,
          });
        }
      } else if (e.key === "GoBack") {
        // need finish web app
        navigate("/", { replace: true });
        setDoc(doc(db, "thinq_talk", "application"), {
          poweron: false,
        });
      }
    },
    [navigate, image, slider, youtube, kakaotalk, from, message, stdemo]
  );

  const clickListener = useCallback(
    (e) => {
      keyListener({ key: "Enter" });
    },
    [keyListener]
  );

  useEffect(() => {
    window.addEventListener("keydown", keyListener);
    window.addEventListener("click", clickListener);
    return () => {
      window.removeEventListener("keydown", keyListener);
      window.removeEventListener("click", clickListener);
    };
  }, [clickListener, keyListener]);

  return (
    <>
      {debug && (
        <Box sx={{ position: "absolute", top: 0, left: 4 }}>
          <Typography variant="h6" component="div" color="red">
            {event}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          position: "absolute",
          right: 10,
          bottom: 10,
        }}
      >
        <Card
          variant="outlined"
          sx={{
            backgroundColor: "#000000aa",
            width: 480,
            borderRadius: 20,
            p: 3,
            overflow: "visible",
          }}
        >
          <Box sx={{ mt: -5, ml: 5 }}>
            {
              stdemo ?
              (
                <Chip
                label={"Simple Things"}
                variant="outlined"
                sx={{ backgroundColor: "#000000dd", px: 1, py: 2.5 }}
              />
              )
              :
              (
                <Chip
                label={"ThinQ Talk"}
                variant="outlined"
                sx={{ backgroundColor: "#000000dd", px: 1, py: 2.5 }}
                avatar={<Avatar alt="Remy Sharp" src={thinqIcon} />}
              />
              )
            }
          </Box>
          <CardHeader
            avatar={
              <Avatar
                sx={{ width: 56, height: 56 }}
                aria-label="recipe"
                // variant="rounded"
                src={icon}
              />
            }
            title={from}
            titleTypographyProps={{ variant: "h6" }}
            sx={{ pb: 0 }}
          />
          <CardContent sx={{ pt: 0 }}>
            <Paper
              elevation={10}
              sx={{
                mb: 2,
                p: 6,
                borderRadius: 4,
                minHeight: "160px",
              }}
            >
              <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
                {message + (youtube ? "\n(동영상)" : "")}
              </Typography>
            </Paper>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              {kakaotalk ? (
                <>
                  리모콘의 "OK"를 눌러 <br /> TV화면으로 돌아가기
                </>
              ) : stdemo ? (
                <>
                  Press "OK" on the remote control <br /> to return to the TV screen
                </>
              ) : standup ? (
                <>
                  일어서면 메시지는 자동으로 사라집니다. <br /> 리모콘의 "OK" 버튼을 눌러 알림 확인
                </>
              ) : popup || fullscreen || complete ? (
                <>
                  Temp 메시지
                </>
              ) :
               (
                <>
                  리모콘의 "OK" 버튼을 눌러 <br /> 자세히 보기
                </>
              )}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default TextMessage;
