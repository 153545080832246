import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useEffect, useState, useRef } from "react";
import { db } from "../firebase/firebase";
import { useNavigate, useLocation } from "react-router-dom";

const useNavigation = () => {
  const nativeNavigate = useNavigate();
  const navigate = (destination, options) => {
    nativeNavigate(destination, options);
  };

  return { navigate };
};

function Idle(props) {
  const [state, setState] = useState(".");
  const navigation = useRef(useNavigation());
  const { end } = props;

  useEffect(() => {
    const msgTypeDocRef = doc(db, "thinq_talk", "message_type");
    setTimeout(async () => {
      const msgTypeSnap = await getDoc(msgTypeDocRef);
      if (!msgTypeSnap.exists || msgTypeSnap.data().type === "") {
        return;
      }
      setState(".");
      setDoc(msgTypeDocRef, { type: "" }).then(() => {
        const stored = msgTypeSnap.data().type;
        setDoc(msgTypeDocRef, { type: stored });
      });
    }, 5000);

    const url = new URL(window.location.href);
    const queryString = new URLSearchParams(url.search);
    const mode = queryString.get('mode');
  
    if (mode != null) {
      if (mode === "state") {
        const state = queryString.get('state');
        if (state != null && !end) {
          const stateRoute = `/${state}`;
          navigation.current.navigate(stateRoute, { replace: true });
        }
      } else if (mode === "show_popup") {
        let inputMsg = queryString.get('msg');
        inputMsg = decodeURI(inputMsg);
        navigation.current.navigate("/show_popup", {
          state: { msg: inputMsg },
        });
      } else if (mode === "show_gpt") {
        let inputMsg = queryString.get('msg');
        inputMsg = decodeURI(inputMsg);
        navigation.current.navigate("/show_gpt", {
          state: { msg: inputMsg },
        });
      }
    }


  }, []);
  return (
    <Box sx={{ position: "absolute", top: 0, left: 4 }}>
      <Typography variant="h6" component="div" color="red">
        {state}
      </Typography>
    </Box>
  );
}

export default Idle;
