import { createTheme, ThemeProvider } from "@mui/material";
import { HashRouter, useRoutes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import MQTTObserver from "./mqtt/MQTTObserver";
import Idle from "./screen/Idle";
import ImageViewer from "./screen/ImageViewer";
import LauncherMessage from "./screen/LauncherMessage";
import Popup from "./screen/Popup";
import SliderMessage from "./screen/SliderMessage";
import TextMessage from "./screen/TextMessage";
import DemoScreen from "./screen/DemoScreen";
import TvHandler from "./components/TvHandler";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    fontSize: 32,
  },
});

let cnt = 0;

function App() {
  return (
    <RecoilRoot>
      <ThemeProvider theme={darkTheme}>
        <HashRouter>
          <MQTTObserver />
          <TvHandler />
          <AppRoutes />
        </HashRouter>
      </ThemeProvider>
    </RecoilRoot>
  );
}

const AppRoutes = () => {
  const routes = useRoutes([
    { path: "/", element: <Idle /> },
    { path: "/end", element: <Idle end /> },
    { path: "/demo", element: <Popup key={cnt++} /> },
    { path: "/image", element: <TextMessage image key={cnt++} /> },
    { path: "/stdemo", element: <TextMessage stdemo key={cnt++} /> },
    { path: "/slider", element: <TextMessage slider key={cnt++} /> },
    {
      path: "/youtubetalk",
      element: <TextMessage youtube key={cnt++} />,
    },
    {
      path: "/youtube",
      element: <LauncherMessage type={"youtube"} key={cnt++} />,
    },
    { path: "/kakaotalk", element: <TextMessage kakaotalk key={cnt++} /> },
    { path: "/detail", element: <SliderMessage key={cnt++} /> },
    { path: "/viewer", element: <ImageViewer /> },
    {
      path: "/shopping",
      element: <LauncherMessage type={"browser"} key={cnt++} />,
    },
    {
      path: "/netflix",
      element: <LauncherMessage type={"netflix"} key={cnt++} />,
    },
    { path: "/drawmemo", element: <SliderMessage memo key={cnt++} /> },

    { path: "/notify_standup", element: <DemoScreen standup key={cnt++} /> },
    { path: "/progress_popup", element: <DemoScreen popup key={cnt++} /> },
    { path: "/progress_fullscreen", element: <DemoScreen fullscreen key={cnt++} /> },
    { path: "/complete", element: <DemoScreen complete key={cnt++} /> },
    { path: "/show_popup", element: <DemoScreen showpopup key={cnt++} /> },
    { path: "/show_gpt", element: <DemoScreen gpt key={cnt++} /> },

  ]);
  return routes;
};

export default App;
