import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { clientID } from "../data/clientID";
import { useRecoilState, useRecoilValue } from "recoil";

const useNavigation = () => {
  const nativeNavigate = useNavigate();
  const navigate = (destination, options) => {
    nativeNavigate(destination, options);
  };

  return { navigate };
};

function TvHandler(props) {
  const navigation = useRef(useNavigation());
  const [, setClientId] = useRecoilState(clientID);
  const clientId = useRecoilValue(clientID);
  const keyListener = useCallback(
    (e) => {
      if (e.key) {
        const message = {
          keyEvent: {
            code: e.code,
            key: e.key,
            keyCode: e.keyCode,
            type: e.type,
          },
        };

        // window.tvlog(message);
        if (clientId) {
          window.connectManager.sendMessage(clientId, message);
        }

        if (e.key === "GoBack") {
          window.close();
        }
      }
    },
    [clientId]
  );
  const clickListener = useCallback(
    (e) => {
      keyListener({
        code: "Enter",
        key: "Enter",
        keyCode: -1,
        type: "click",
      });
    },
    [keyListener]
  );

  const routeMessage = useCallback((messageString) => {
    const jsonObj = JSON.parse(messageString);
    // window.tvlog("routeMessage", jsonObj);
    if (jsonObj.mode === "popup") {
      navigation.current.navigate("/demo", { state: jsonObj });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", keyListener);
    window.addEventListener("click", clickListener);
    return () => {
      window.removeEventListener("keydown", keyListener);
      window.removeEventListener("click", clickListener);
    };
  }, [clickListener, keyListener]);
  useEffect(() => {
    // window.tvlog("start TvHandler");
    window.connectManager = new window.connectsdk.ConnectManager();
    window.connectManager.on("join", function (client) {
      // window.tvlog("client joined with id " + client.id);
      setClientId(client.id);

      //팝업을 버티기 위해서 연결되면 up 을 p2p 로 한번날림.
      window.connectManager.sendMessage(client.id, 'up');
    });
    window.connectManager.on("message", function (data) {
      // window.tvlog("onMessage from client", data);
      var from = data.from;
      var message = data.message;
      var messageString;
      var isJSON = false;

      if (typeof message == "string") {
        messageString = message;
      } else if (typeof message == "object") {
        messageString = JSON.stringify(message);
        isJSON = true;
        routeMessage(messageString);
      }
      // window.tvlog(
      //   `Received message(JSON ${isJSON}) from ${from}:`,
      //   messageString
      // );
    });
    window.connectManager.init();
  }, [routeMessage]);
  return null;
}

export default TvHandler;
