import { Avatar, CardActionArea, CardHeader, Chip, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import thinqIcon from "../assets/images/ic_launcher_thinq.png";
import ninizAni from "../assets/images/niniz_crap.gif";
import "../assets/css/default.css";
import { debug } from "../data/constants";
import { db } from "../firebase/firebase";
import ProgressBar from "./ProgressBar";
import { countData } from "../data/countData";
import { clientID } from "../data/clientID";
import { useRecoilState, useRecoilValue } from "recoil";
import { useMqttState, useSubscription } from 'mqtt-react-hooks';
import VoiceLottieAnimation from "../components/VoiceLottie";

function DemoScreen(props) {
  const { standup, popup, fullscreen, complete, showpopup, gpt } = props;
  const [event, setEvent] = useState("null");
  const [icon, setIcon] = useState(thinqIcon);
  const [from, setFrom] = useState(null);
  const [messages, setMessages] = useState(null);
  const [messages2, setMessages2] = useState(null);
  const navigate = useNavigate();
  const [, setSeconds] = useRecoilState(countData);
  const seconds = useRecoilValue(countData);
  const clientId = useRecoilValue(clientID);
  const location = useLocation();
  const { message } = useSubscription([
    '@topic/gpt'
  ]);

  const url = new URL(window.location.href);
  const queryString = new URLSearchParams(url.search);
  const mode = queryString.get('mode');

  useEffect(() => {
    if (mode === 'show_gpt') {
      if (message?.topic === '@topic/gpt') {
        setMessages(message?.message);
      }
    }
  }, [message, mode]);

  useEffect(() => {
    if (standup) {
      setMessages("1시간 동안 앉아서 TV만 보셨어요.\n일어서서 일분 동안 몸을 움직이세요.");
      setSeconds(60);
    } else if (popup) {
      setMessages("일어선 채로 1분 동안 움직이세요.");
    } else if (fullscreen) {
      setMessages("아직 1분이 되지 않았는데 앉으시다뇨?");
      setMessages2("기운내서 다시 일어나 보세요!");
    } else if (complete) {
      setMessages("1분 움직이기 챌린지 완료");
      setSeconds(60);
      const timer = setTimeout(() => {
        navigate("/end", { replace: true });
        window.connectManager.sendMessage(clientId, 'complete_tts');
        window.connectManager.sendMessage(clientId, 'exit');
      }, 3000); // 3초 후에 팝업을 닫음
 
      return () => clearTimeout(timer);
    } else if (showpopup) {
      const formattedMessage = location.state.msg.replace(/\\n/g, '\n');
      console.log('formattedMessage : ', formattedMessage);
      const test = "취침 전 약먹기 시간이에요.\n건강을 위해서 취침 전에 꼭 챙겨드세요.";
      // setMessage(location.state.msg || "약먹을 시간입니다.");
      setMessages(formattedMessage);
      const timer = setTimeout(() => {
        navigate("/end", { replace: true });
        window.connectManager.sendMessage(clientId, 'exit');
      }, 5000); // 5초 후에 팝업을 닫음
    } else if (gpt) {
      const formattedMessage = location.state.msg.replace(/\\n/g, '\n');
      console.log('formattedMessage : ', formattedMessage);
      setMessages(formattedMessage);
      const timer = setInterval(() => {
        window.connectManager.sendMessage(clientId, 'up');
      }, 60000); // 60초마다 갱신 시도
    }
  }, [standup, popup, fullscreen, complete, showpopup, gpt]);

  const keyListener = useCallback(
    (e) => {
      setEvent(e.key);
      if (e.key === "Enter") {
        if (standup) {
          navigate("/progress_popup", { replace: true });
        } else if (complete) {
          navigate("/end", { replace: true });
        } else if (showpopup) {
          navigate("/end", { replace: true });
          window.connectManager.sendMessage(clientId, 'exit');
        } else if (gpt) {
          navigate("/end", { replace: true });
          window.connectManager.sendMessage(clientId, 'complete_tts'); //변경하기 귀찮아서 재사용 webostv 의 when webapp_complete
          window.connectManager.sendMessage(clientId, 'exit');
        }
      } else if (e.key === "GoBack") {
        // need finish web app
        navigate("/end", { replace: true });
        setDoc(doc(db, "thinq_talk", "application"), {
          poweron: false,
        });
      }
    },
    [navigate, standup, complete, showpopup, gpt]
  );

  const clickListener = useCallback(
    (e) => {
      keyListener({ key: "Enter" });
    },
    [keyListener]
  );

  useEffect(() => {
    window.addEventListener("keydown", keyListener);
    window.addEventListener("click", clickListener);
    return () => {
      window.removeEventListener("keydown", keyListener);
      window.removeEventListener("click", clickListener);
    };
  }, [clickListener, keyListener]);

  // const [seconds, setSeconds] = useState(60);
  const [isPaused, setIsPaused] = useState(true);

  const handlePauseResume = () => {
    setIsPaused((prevIsPaused) => !prevIsPaused);
  };

  useEffect(() => {
    if (seconds <= 0) {
      navigate("/complete", { replace: true });
    }
  }, [seconds]);

  return (
    <>
      {debug && (
        <Box sx={{ position: "absolute", top: 0, left: 4 }}>
          <Typography variant="h6" component="div" color="red">
            {/* {event} */}
            {window.location.href}
          </Typography>
        </Box>
      )}
      { fullscreen && (
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1300,
        }}
        >
          <Card
            sx={{
              width: '100%', height: '100%',
              backgroundColor: "#000000aa",
              overflow: "visible",
            }}
          >
            <CardContent sx={{ pt: 0}} alignItems="center">
              <div class="container"><br /><br /><br /><img src={ninizAni}/></div>
              <Typography variant="h6" gutterBottom sx={{ whiteSpace: "pre-line", textAlign: "center" }}>
                {messages}
              </Typography>
              <Typography variant="h5" sx={{ whiteSpace: "pre-line", textAlign: "center" }}>
                {messages2}
              </Typography>
              <br />
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <div class="barcontainer"><ProgressBar isPaused={true}/></div>
              </div>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                fontSize="28px"
                sx={{ textAlign: "center" }}
              >
                일어나지 않으면 10초 뒤에 TV가 자동으로 꺼집니다.
              </Typography>
            </CardContent>
          </Card>
        </Box>
      )}
      { !fullscreen && (
        <Box
          sx={{
            position: "absolute",
            right: 10,
            bottom: 10,
          }}
        >
          <Card
            variant="outlined"
            sx={{
              backgroundColor: "#000000aa",
              minWidth: 520,
              maxWidth: 900, // Add maxWidth to restrict the width
              borderRadius: 5,
              p: 1,
              overflow: "visible",
            }}
          >
          { (standup || showpopup || gpt) && (
            <CardHeader
              avatar={
                <Avatar
                  sx={{ width: 48, height: 48 }}
                  aria-label="recipe"
                  // variant="rounded"
                  src={icon}
                />
              }
              title={"ThinQ Talk"}
              titleTypographyProps={{ fontSize: "32px" }}
              sx={{ pb: 0 }}
              action={gpt ? <VoiceLottieAnimation /> : null}
            />
            )}
            { !standup && !showpopup && !gpt && (
            <CardHeader
              avatar={
                <Avatar
                  sx={{ width: 56, height: 56 }}
                  aria-label="recipe"
                  // variant="rounded"
                  src={icon}
                />
              }
              title={messages}
              titleTypographyProps={{ variant: "h6" }}
              sx={{ pb: 0 }}
            />
          )}
            <CardContent sx={{ pt: 0 }}>
              { (standup || showpopup || gpt) && (
                <Typography variant="h6" sx={{ whiteSpace: "pre-line" , wordWrap: "break-word", pt:1}}>
                  {messages}
                </Typography>
              )}
              <Typography
                variant="subtitle1"
                color="text.secondary"
                sx={{ textAlign: "center" }}
              >
                {standup ? (
                  <>
                  <br />
                    일어서면 메시지는 자동으로 사라집니다. <br /> 리모콘의 "OK" 버튼을 눌러 알림 확인
                  </>
                ) : popup ? (
                    <></>
                ) : complete ? (
                  <></>                
                ) : (showpopup || gpt) ? 
                (
                  <>
                    확인
                  </>  
                ) :
                (
                  <>
                    리모콘의 "OK" 버튼을 눌러 <br /> 자세히 보기
                  </>
                )}
              </Typography>
              { popup && (<><br /><ProgressBar isPaused={false}/></>)}
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
}

export default DemoScreen;
