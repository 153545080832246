import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { Connector } from 'mqtt-react-hooks';

ReactDOM.render(
  <React.StrictMode>
    <Connector
      // brokerUrl="ws://10.157.46.91:9001"
      brokerUrl="ws://bonkab.com:9001"
      options={ {"protocol": "ws"}}>
      <App />
    </Connector>
  </React.StrictMode>,
  document.getElementById("root")
);
