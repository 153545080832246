import React, { useEffect, useRef } from 'react';
import { LinearProgress, Box, Typography, Grid } from '@mui/material';
import { countData } from "../data/countData";
import { useRecoilState, useRecoilValue } from "recoil";

const CountdownLinearProgressBar = ({ isPaused }) => {
  const intervalRef = useRef(null);
  const [, setSeconds] = useRecoilState(countData);
  const seconds = useRecoilValue(countData);

  useEffect(() => {
    if (!isPaused) {
      intervalRef.current = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 333);

    } else if (isPaused && intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [isPaused, setSeconds]);

  useEffect(() => {
    if (seconds <= 0) {
      setTimeout(() => {
        setSeconds(60);
        // setProgress(100);
      }, 333);
    }
  }, [seconds, setSeconds]);

  // const marks = [];
  // for (let i = 0; i <= 60; i += 10) {
  //   marks.push(i);
  // }
  const marks = [10, 20, 30, 40, 50];
  const progress = (seconds / 60) * 100;

  let progressColor;
  if (progress < 16) {
    progressColor = '#fa2f28';
  } else if (progress < 49) {
    progressColor = '#ef933c';
  } else {
    progressColor = '#52b74b';
  }

  return (
    <Box width="100%" display="flex" alignItems="center">
      <Box width="100%" position="relative" display="flex" alignItems="center">
        <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ 
                height: 30,
                width: '100%',
                borderRadius: 2,
                backgroundColor: '#d3d3d3', // 배경 색상
                '& .MuiLinearProgress-bar': {
                    backgroundColor: progressColor, // 프로그레스 바 색상
                }, 
            }}
        />
        <Grid container spacing={0} justifyContent="space-between" sx={{ position: 'absolute', width: '100%' }}>
            {marks.map((mark, index) => (
                <Grid item key={index} sx={{ position: 'absolute', left: `${(mark / 60) * 100}%`, transform: 'translateY(-50%)' }}>
                <Box
                    sx={{
                    width: 2,
                    height: 30,
                    backgroundColor: '#555555',
                    }}
                />
                </Grid>
            ))}
        </Grid>
      </Box>
      <Box ml={2} minWidth={120} display="flex" alignItems="center">
        <Typography fontSize="28px" noWrap>
        {seconds}초 남음
        </Typography>
      </Box>
    </Box>
  );
};

export default CountdownLinearProgressBar;
