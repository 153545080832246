import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useResetRecoilState } from "recoil";
import singleImg from "../assets/images/heart.gif";
import { imageUrlsState } from "../data/imageUrls";
import { db, storage } from "../firebase/firebase";
import { useMqttState, useSubscription } from 'mqtt-react-hooks';

let initDB = false;

const useNavigation = () => {
  const nativeNavigate = useNavigate();
  const navigate = (destination, options) => {
    nativeNavigate(destination, options);
  };

  return { navigate };
};

function MQTTObserver(props) {
  const [msgtype, setMsgtype] = useState("");
  const [, setImageUrls] = useRecoilState(imageUrlsState);
  const resetImageUrls = useResetRecoilState(imageUrlsState);
  const navigation = useRef(useNavigation());

  const { connectionStatus } = useMqttState();
  const { message } = useSubscription([
    '@topic/screen_change'
  ]);

  const url = new URL(window.location.href);
  const queryString = new URLSearchParams(url.search);
  const mode = queryString.get('mode');

  useEffect(() => {
    if (mode === "state") {
      if (message?.topic === '@topic/screen_change') {
        if (message?.message === "notify_standup") {
          navigation.current.navigate("/notify_standup");
        } else if (message?.message === "progress_popup") {
          navigation.current.navigate("/progress_popup");
        } else if (message?.message === "progress_fullscreen") {
          navigation.current.navigate("/progress_fullscreen");
        } else if (message?.message === "complete") {
          navigation.current.navigate("/complete");
        }
      }
    }
  }, [message, mode]);


  const predownload = useCallback(() => {
    const listRef = ref(storage, "Device.tv");
    listAll(listRef)
      .then((res) => {
        setImageUrls((prev) => {
          prev.forEach((url) => window.URL.revokeObjectURL(url));
          return prev;
        });
        resetImageUrls();
        res.items.forEach((itemRef) => {
          getDownloadURL(itemRef).then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = "blob";
            xhr.onload = (event) => {
              const blob = xhr.response;
              setImageUrls((prev) => [
                ...prev,
                window.URL.createObjectURL(blob),
              ]);
              window.URL.revokeObjectURL(blob);
            };
            xhr.open("GET", url);
            xhr.send();
          });
        });
      })
      .catch((error) => {
        console.log("listAll error", error);
      });
  }, [resetImageUrls, setImageUrls]);

  // useEffect(() => {
  //   setDoc(doc(db, "thinq_talk", "application"), {
  //     poweron: true,
  //   });
  //   const unsubscribe = onSnapshot(
  //     doc(db, "thinq_talk", "message_type"),
  //     (doc) => {
  //       if (!initDB) {
  //         initDB = true;
  //         return;
  //       }
  //       console.log("onSnapshot", "message_type", doc.data().type);
  //       setMsgtype(doc.data().type);
  //     }
  //   );
  //   return unsubscribe;
  // }, []);

  // useEffect(() => {
  //   console.log("msgtype: ", msgtype);
  //   if (msgtype === "st_demo") {
  //     navigation.current.navigate("/stdemo");
  //   } else if (msgtype === "single") {
  //     setImageUrls([singleImg]);
  //     navigation.current.navigate("/image");
  //   } else if (msgtype === "slider") {
  //     predownload();
  //     navigation.current.navigate("/slider");
  //   } else if (msgtype === "youtubetalk") {
  //     navigation.current.navigate("/youtubetalk");
  //   } else if (msgtype === "youtube") {
  //     navigation.current.navigate("/youtube");
  //   } else if (msgtype === "shopping") {
  //     navigation.current.navigate("/shopping");
  //   } else if (msgtype === "netflix") {
  //     navigation.current.navigate("/netflix");
  //   } else if (msgtype === "kakaotalk") {
  //     navigation.current.navigate("/kakaotalk");
  //   } else if (msgtype === "memo") {
  //     predownload();
  //     navigation.current.navigate("/drawmemo", {
  //       state: { keyevent: "memo", message: "" },
  //     });
  //   }

  //   setDoc(doc(db, "thinq_talk", "message_type"), {
  //     type: "",
  //   });
  // }, [msgtype, setImageUrls, predownload]);
  return null;
}

export default MQTTObserver;
