import runes from "runes";

const debug = true;

export function logd(...args) {
  if (debug && window.tvlog) {
    try {
      window.tvlog(...args);
    } catch (e) {}
  } else {
    console.log(...args);
  }
}

//------------------------------------

export function getChromeVersion() {
  var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  if (raw === null) {
    raw = navigator.userAgent.match(/Chr0m(e|ium)\/([0-9]+)\./); // webOS
  }
  let ver = raw ? parseInt(raw[2], 10) : 0;
  logd("getChromeVersion: ", navigator.userAgent, " ver:", ver);
  return ver;
}

export function getEllipsisString(str, maxLength) {
  const str2 = runes.substr(str, 0, maxLength);
  if (str2.length < str.length) {
    return str2 + "...";
  }
  return str;
}

export function hasEmoji(str) {
  const regexExp =
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
  return regexExp.test(str);
}
