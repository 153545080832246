

import React from 'react';
import Lottie from 'lottie-react';
import voiceLottie from '../assets/lottie/voice.json'

const VoiceLottieAnimation = () => {
  return (
    <div style={{ width: 100, height: 100 }}>
      <Lottie animationData={voiceLottie} loop={true} />
    </div>
  );
};

export default VoiceLottieAnimation;
