import Alarm from "../assets/audio/Alarm.mp3";
import "../assets/css/default.css";
import avatarMain from "../assets/images/ariana_avatar.png";
import dishwasher from "../assets/images/ic_device_list_dish_washer_default-01.png";
import washer from "../assets/images/ic_device_list_front_load_washer_default-01.png";
import rcleaner from "../assets/images/ic_device_list_robot_cleaner_default-01.png";
import logo from "../assets/images/logo.png";
import "./Popup.css";

import { Avatar } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getEllipsisString, hasEmoji, logd } from "../components/Util";

let timerId;

function Popup() {
  const [popupClass, setPopupClass] = useState("popup popup-alert type2 on");
  const buttonOkRef = useRef();
  const buttonCancelRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  const message = {
    type: location.state.type,
    senderName: location.state.from,
    text: location.state.message,
  };

  let profile;
  if (message.senderName === "식기세척기") {
    profile = dishwasher;
  } else if (message.senderName === "세탁기") {
    profile = washer;
  } else if (message.senderName === "로봇청소기") {
    profile = rcleaner;
  } else {
    profile = avatarMain;
  }

  let senderName = getEllipsisString(
    message.senderName,
    hasEmoji(message.senderName) ? 6 : 8
  );

  function handleAnimationEnd() {
    if (popupClass.includes("off")) {
      return;
    }
    logd("Popup - handleAnimationEnd");

    buttonOkRef.current.focus();

    timerId = setTimeout(() => {
      logd("Popup - 10sec auto close");
      closePopup();
    }, 10000);

    window.addEventListener("keydown", onKeyDown);

    playAudio();
  }

  function onKeyDown(e) {
    logd("Popup - onKeyDown:", e.key);
    if (e.key === "GoBack" || e.key === "Escape" || e.key === "Enter") {
      closePopup();
    } else if (e.key === "ArrowLeft") {
      buttonOkRef.current.focus();
    } else if (e.key === "ArrowRight") {
      buttonCancelRef.current.focus();
    }
  }
  function onClick(e) {
    onKeyDown({ key: "Enter" });
  }

  function closePopup() {
    logd("Popup - closePopup");
    window.removeEventListener("keydown", onKeyDown);

    if (timerId) {
      clearTimeout(timerId);
    }
    setPopupClass("popup popup-alert type2 off");

    setTimeout(() => {
      navigate("/", { replace: true });
      window.close();
    }, 1100);
  }

  function handleBlur(e) {
    logd("Popup - handleBlur");
    e.target.focus();
  }

  useEffect(() => {
    logd("location.state", location);
  }, [location]);

  return (
    <>
      <div id="wrap" className="main">
        <div className="frame_popup on">
          <div className="popup_body">
            <div id="wrap" className="sub">
              <div className={popupClass} onAnimationEnd={handleAnimationEnd}>
                <div className="popup_header">
                  <h1>
                    <img src={logo} alt="ThinQ Talk" />
                    <span className="hide">알림창</span>
                  </h1>
                </div>
                <div className="popup-content">
                  <div className="content_area">
                    <div className="user_area">
                      <Avatar
                        className="user_image"
                        alt="프로필 이미지"
                        src={profile}
                        sx={{ width: 48, height: 48, marginRight: 2 }}
                      />
                      <span className="user_name">{senderName}</span>
                    </div>
                    <p className="text">{message.text}</p>
                  </div>
                </div>
                <div className="popup-footer">
                  <div
                    tabIndex={0}
                    className="popup_btn preview"
                    ref={buttonOkRef}
                    onClick={onClick}
                  >
                    {message.type === "launch" ? "연결하기" : "확인"}
                  </div>
                  {message.senderName === "로봇청소기" && (
                    <>
                      <div style={{ width: "100px" }} />
                      <div
                        tabIndex={0}
                        className="popup_btn preview"
                        ref={buttonCancelRef}
                        onClick={onClick}
                      >
                        {"취소"}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function playAudio() {
  logd("Popup - playAudio");
  const context = new AudioContext();
  window
    .fetch(Alarm)
    .then((response) => {
      logd("Popup - response: ", response);
      return response.arrayBuffer();
    })
    .then((arrayBuffer) => context.decodeAudioData(arrayBuffer))
    .then((audioBuffer) => {
      logd("Popup - audioBuffer: ", audioBuffer);
      play(audioBuffer);
    });

  function play(audioBuffer) {
    const source = context.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(context.destination);
    source.start();
    logd("Popup - play DONE");
  }
}

export default Popup;
